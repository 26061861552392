import React from 'react'
import './footer.css'
function FooterM() {
  return (
    <div>
     <div className=' background' >
       <p className='text-center pt-3'>Ebhoom © </p>
       <p className='ms-5'>COMPANY </p>
       <p className='ms-5'>TERMS</p>
       <p className='ms-5'>FAQS</p>

     {/* <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320"><path fill="#236a80" fill-opacity="1" d="M0,128L80,154.7C160,181,320,235,480,224C640,213,800,139,960,117.3C1120,96,1280,128,1360,144L1440,160L1440,320L1360,320C1280,320,1120,320,960,320C800,320,640,320,480,320C320,320,160,320,80,320L0,320Z">     <text x="5" y="30" fill="none" stroke="red" font-size="35">I love SVG!</text>
     </path>
     <text className='text-center' x="505" y="30" fill="black" font-size="25" >Ebhoom ©  </text>     </svg> */}
     </div>
    </div>
  )
}

export default FooterM